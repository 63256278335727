import useLocalization from "./useLocalization";
import { FormFieldsVariables } from "../models/app";
import dayjs, { Dayjs } from "dayjs";

const useFormFields = (
  params: FormFieldsVariables,
  bookingType: string,
  selectedDate: string
) => {
  const { strings } = useLocalization();

  const bookingFields = (venue: any) => {
    const mealOptions: any[] = [
      {
        value: "Dinner",
        label: "Dinner",
      },
      {
        value: "Breakfast",
        label: "Breakfast",
      },
    ];

    const areas: any[] = venue ? venue.areas : [];
    let areaOptions: any[] = areas.map((area: any) => ({
      value: area,
      label: area,
    }));

    const timeSlots: any[] = venue ? venue.timeSlots : [];
    let timeSlotOptions: any[] = timeSlots.map((timeSlot: any) => ({
      value: timeSlot.id,
      label: timeSlot.name.split(" - ")[0],
    }));

    const getAllFridaysSaturdaysAndSundays = (
      startDate: Dayjs,
      endDate: Dayjs
    ): string[] => {
      const eventDates: string[] = [];
      let currentDate = startDate;

      // Loop through all dates from startDate to endDate
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        const day = currentDate.day();

        // Check if the current date is a Friday (5), Saturday (6), or Sunday (0)
        if (day === 5 || day === 6 || day === 0) {
          eventDates.push(currentDate.format("YYYY-MM-DD"));
        }

        // Move to the next day
        currentDate = currentDate.add(1, "day");
      }

      return eventDates;
    };

    // If the booking type is not "Normal Booking", filter timeSlotOptions by specific ids
    // if (bookingType !== "Normal Booking") {
    //   const allowedIds = [
    //     "dccef392-036f-4284-a550-35e486d2825e",
    //     "1f63f38d-1bdf-4845-835c-92053ce61ecf",
    //     "b1d82e73-29be-45cc-a8cf-3f32903e236f",
    //   ];
    //   timeSlotOptions = timeSlotOptions.filter((option: any) =>
    //     allowedIds.includes(option.value)
    //   );
    // }

    // Define the date range for event dates
    const startDate = dayjs("2025-01-26");
    const endDate = dayjs("2025-02-28");

    // Get all Fridays, Saturdays, and Sundays within the date range
    const eventDates = getAllFridaysSaturdaysAndSundays(startDate, endDate);

    // Check if the selectedDate is one of the event dates
    const isEventDate = eventDates.includes(selectedDate);

    // If the selectedDate is an event date, filter timeSlotOptions by specific ids
    // if (isEventDate) {
    //   const allowedIds = [
    //     "dccef392-036f-4284-a550-35e486d2825e",
    //     "1f63f38d-1bdf-4845-835c-92053ce61ecf",
    //     "b1d82e73-29be-45cc-a8cf-3f32903e236f",
    //   ];
    //   timeSlotOptions = timeSlotOptions.filter((option: any) =>
    //     allowedIds.includes(option.value)
    //   );
    // }

    if (isEventDate) {
      const dayOfWeek = dayjs(selectedDate).day(); // Get the day of the week (0 = Sunday, 5 = Friday, 6 = Saturday)

      let allowedIds: string[];
      if (dayOfWeek === 5 || dayOfWeek === 6) {
        // Friday or Saturday
        allowedIds = [
          "dccef392-036f-4284-a550-35e486d2825e",
          "1f63f38d-1bdf-4845-835c-92053ce61ecf",
        ];
      } else if (dayOfWeek === 0) {
        // Sunday
        allowedIds = ["b1d82e73-29be-45cc-a8cf-3f32903e236f"];
      } else {
        // Default (should not happen since isEventDate is true)
        allowedIds = [];
      }

      // Filter timeSlotOptions based on allowedIds
      timeSlotOptions = timeSlotOptions.filter((option: any) =>
        allowedIds.includes(option.value)
      );
    }

    return [
      {
        name: "pax",
        label: "Pax",
        placeholder: "Persons",
        type: "number",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "date",
        label: "Date",
        placeholder: "Booking Date",
        type: "date",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "time",
        label: "Time",
        type: "select",
        autoFocus: false,
        defaultValue:
          timeSlotOptions && timeSlotOptions.length > 0
            ? timeSlotOptions[0].value
            : "",
        options: timeSlotOptions,
      },
      {
        name: "area",
        label: "Area",
        type: "select",
        autoFocus: false,
        defaultValue:
          areaOptions && areaOptions.length > 0 ? areaOptions[0].value : "",
        options: areaOptions,
      },
      {
        name: "meal",
        label: "Meal",
        type: "select",
        autoFocus: false,
        defaultValue:
          mealOptions && mealOptions.length > 0 ? mealOptions[0].value : "",
        options: mealOptions,
      },
      {
        name: "coupon",
        label: "Coupon",
        placeholder: "Coupon",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "note",
        label: "Note",
        placeholder: "General Note",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
    ];
  };

  const userInfoFields = () => {
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Full Name",
        type: "InputBase",
        autoFocus: true,
        defaultValue: "",
      },
      {
        name: "mobile",
        label: strings.mobile,
        placeholder: "01222222222",
        type: "phoneNumber",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "email",
        label: strings.email,
        placeholder: "example@example.com",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
    ];
  };

  return {
    bookingFields,
    userInfoFields,
  };
};

export default useFormFields;
